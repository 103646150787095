/** @jsx jsx */
// import React from "react"
import { Styled, jsx } from "theme-ui";
// import { Link } from "gatsby";

import { graphql, StaticQuery } from "gatsby";

import Main from "../components/Main";
import SEO from "../components/SEO";
import Title from "../components/Title";
import Container from "../components/Container";
import Image from "../components/Image";

const config = require("../../config.json");
// const raPath = config.RA_PATH;

export default () => (
  <Main>
    <SEO title="Page two" />

    <Container>
      <Title
        titleName="Lorem ipsum…"
        titleSub="Unsere Rechtsanwälte"
        titleLink="Gespräch vereinbaren"
        titleURL=""
      />

      <StaticQuery
        query={graphql`
          query RaeQuery {
            allMarkdownRemark(
              filter: { fileAbsolutePath: { regex: "/rechtsanwalt/" } }
            ) {
              edges {
                node {
                  frontmatter {
                    name
                    vorname
                    unteruberschrift
                    titel
                  }
                  parent {
                    ... on File {
                      name
                      relativeDirectory
                    }
                  }
                }
              }
            }
          }
        `}
        render={(data) => (
          <div>
            {data.allMarkdownRemark.edges.map((e) => (
              <a
                href={[config.RA_PATH + e.node.parent.name]}
                key={e.node.parent.name}
              >
                <Image />
                <Styled.h4>{e.node.frontmatter.name}</Styled.h4>
                {e.node.frontmatter.unteruberschrift}
                {e.node.frontmatter.titel}
              </a>
            ))}
          </div>
        )}
      />
    </Container>
  </Main>
);
